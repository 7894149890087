import React from 'react'
import ReactDOM from 'react-dom/client'

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';

export const VideoPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
  <App
     logo = {"./images/pblogo.png"} />
    
   <Toptitle title={"Videography"}/>

   <Showreel 
   source={"https://www.youtube.com/embed/OzRrI5SIlWw?si=eoVX0nhVcS6Q_Ebt"}
   description={["Check out my videography showreel to see a showcase of my work, including engaging videos and expertly edited showreels for top clients like the NHS and leading construction firms. Click the link to watch and discover the quality and creativity I bring to every project!"]}
   version={"handwritten"}
   alignleft={"0px"}/>

<TitleLft titlename={"Certifications"} />

<Certification 
    logo={"/images/ACI logo.jpg"} 
    title={"Avid Cerfited media encoder"} 
    description={""}
    />
  <Certification 
    logo={"/images/protools.png"} 
    title={"Avid certified Pro tools"} 
    description={""}
    />

  <Block details ={"I possess strong skills in all aspects of videography, including operating a wide range of cameras, recording and processing audio, and editing the final footage. Capturing the perfect shot involves a blend of many elements, and it’s something I’ve dedicated significant effort to mastering. My expertise extends to creating compelling visual narratives, utilizing advanced editing software, and ensuring high-quality production standards in every project."}
  margin={200}/>
 

 <Title title={"Intructional videos"}/>

 <Showreel 
   source={"https://www.youtube.com/embed/f_7krzZLxAE?si=y6jnY6g-Bkd8pIIY"}
   description={["I excel in creating high-quality instructional videos that effectively communicate complex information in an easy-to-understand manner.", "My skills encompass all stages of video production, from scripting and storyboarding to filming and editing.", "I utilize a variety of cameras and audio equipment to ensure clear visuals and sound.", "Additionally, my expertise in post-production allows me to add engaging graphics, animations, and subtitles to enhance the learning experience. My attention to detail and commitment to clarity ensure that each instructional video is both informative and engaging for the audience."]}
   version={"handwritten"}
   colour = {"white"}
   />

<TitleLft titlename={"Wedding videos"} 
colour ={"white"}/>

<Showreel 
   source={"https://www.youtube.com/embed/wLl7aS0MC7I?si=0NSO5EXlc-xXhILf"}
   description={["I specialize in creating beautiful and memorable wedding videos that capture the essence of each couple’s special day. ","With a keen eye for detail and a passion for storytelling, I ensure that every significant moment is beautifully filmed and edited. My expertise in using high-quality cameras and audio equipment, combined with my skills in post-production, allows me to produce videos that are both visually stunning and emotionally resonant. Each wedding video I create is a unique reflection of the couple’s love story, crafted to be cherished for years to come."]}
   version={"handwritten"}
   colour ={"white"}
   alignleft={"0vw"}/>

<Title title={"Sports and action"}
colour ={"white"}/>

<Showreel 
  source={"https://www.youtube.com/embed/4V3vujyp9Ek?si=yTW2kfxGVYsCXaHY"}
  description={["I excel in creating dynamic sports and action films that capture the intensity and excitement of the moment."," With a strong understanding of motion and timing, I use high-quality cameras and advanced filming techniques to follow the fast-paced action seamlessly. My expertise in editing ensures that each film is not only visually stunning but also tells a compelling story, highlighting the athleticism and energy of the subjects. ", " Whether it’s a high-stakes game or an adrenaline-pumping adventure, I bring the thrill of sports and action to life on screen."]}
  version={"handwritten"}
  colour ={"white"}
  alignleft={"0vw"}/>

<TitleLft titlename={"Drama"}
colour ={"white"} />

<Showreel 
   source={"https://www.youtube.com/embed/M8XIxN2kc4E?si=eoPFvDydlixQllFu"}
   description={["I excel in creating compelling short dramas for both film and advertising, "," bringing stories to life with emotional depth and visual flair. My expertise spans from scriptwriting and directing to filming and editing, ensuring each project is crafted with precision and creativity. ", "I excel at capturing nuanced performances and creating engaging narratives that resonate with audiences. Whether it’s a heartfelt story for a film or a captivating advert, I bring a unique blend of artistic vision and technical skill to every production, making each short drama a memorable experience."]}
   version={"handwritten"}
   colour ={"white"}
   alignright={"0vw"}/>

<Title title={"Interviews & docs"}
colour ={"white"}/>

<Showreel 
  source={"https://www.youtube.com/embed/0IHPsNafwU8?si=v_wyQpEIM3PJtvML"}
  description={["I have a passion for creating impactful documentaries that tell real-life stories with authenticity and depth.", "My skills encompass all aspects of documentary filmmaking, from research and interviewing to filming and editing. I excel at capturing genuine moments and presenting them in a way that engages and informs the audience.", " With a keen eye for detail and a commitment to truth, I strive to create documentaries that not only entertain but also provoke thought and inspire change.", " Each project is approached with sensitivity and dedication, ensuring that the subjects’ stories are told with respect and integrity."]}
  version={"handwritten"}
  alignleft={"0vw"}
  colour ={"white"}/>

<TitleLft titlename={"Video projects"}
colour={"#6BB22B"}
fontcolour={"white"} />

<Projects
    projectname = {["Whitwam AV solutions",
       "University of creative arts",
        "Food waste charity film",
         
        
          "BT Vanarama",
            
          "NRG Digital",
              "Innis & Gunn",
                "“Alone in a crowd” Music video",
                  "Animal and plant health agency"
                  ]}
    projectdescription = {[
      "Set up live playback of an orchestra, including microphones,lighting and monitors, worked as a camera assistant",
        "Filmed and edited a live business event at a UK university",
          "Filmed and edited a short documentary working with a PR company",
           
             
                "Filmed live football at Aldershot Football Club with training from BT",
                
                "Editing and motion graphics for online client testimonials",
                "Created a proof of concept film using After Effects",
                  "Created VFX and did compositing for music video",
                    "Creation of instructional videos for the civil service",
                     ]} 

    projectlogo = {["/images/complogos/whitwam.jpg",
                  "/images/complogos/uca.png",
                  "/images/complogos/foodwaste.jpg",
                  
                 
                  "/images/complogos/bt.png",
                  "/images/complogos/nrg.png",
                  "/images/complogos/innis.jpg",
                  "/images/complogos/alone.webp",
                  "/images/complogos/apha.png"
                 
                 
      
    ]}  />

<Title title={"Skills & software"}/>

<Skills
skills = {["Video Capture", "Audio recording", "video editing", "audio editing"
  ,"Steady cam operation", "Colour correction", "VFX and animation", "Interview skills"
]}
logo={["/images/pp.jpg", 
  "/images/au.png", 
  "/images/ae.png", 
  "/images/ACI logo.jpg"]}

software={["Premier pro", "Audition", "After Effects", "Avid Media encoder"]}/>

<Contact/>


</React.StrictMode>,
)

)}