import React from 'react'
import ReactDOM from 'react-dom/client'

import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';

import "@fontsource/montserrat";

import App from './header.jsx'
import Jay from './jay.jsx'
import Block from './block.jsx'
import Title from './title.jsx'
import TitleLft from './titlelft.jsx'
import Help from './help.jsx'
import Certification from './certification.jsx'
import Experience from './experience.jsx';
import Projects from './projects.jsx';

import '../css/index.css'
import Project from './project.jsx';
import Reviews from './reviews.jsx';
import Contact from './contact.jsx';
import Toptitle from './toptitle.jsx';
import Showreel from './showreel.jsx';
import Skills from './skills.jsx';
import Screen from './screen.jsx';
import Video from './video.jsx';
import Videolft from './videolft.jsx';
import Ball from './ball.jsx';
import Tutorial from './tutorial.jsx';
import Built from './built.jsx';
import Website from './website.jsx';
import Computer from './computer.jsx';
import Phone from './phone.jsx';
import Tablet from './tablet.jsx';
import Laptop from './laptop.jsx';
import Webdevice from './webdevice.jsx';

export const WebPageComponent = () => {
  return (

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  
    <App
     logo = {"./images/pblogo.png"} />
    

    
   <Toptitle title={"Web Development"} />

   
  <Built/>
 

  <TitleLft titlename={"Certifications"} />

 
  <Certification 
    logo={"/images/harvard.png"} 
    title={"Harvard CS50"} 
    description={""}/>
   

  <Certification 
      logo={"/images/ibm.png"} 
      title={"IBM front end development & react"} 
      description={""}/>

  <Certification 
      logo={"/images/w3.png"} 
      title={"W3 schools certification html, css, javascript"} 
      description={""}/>


<Block
   margin={'100px'}
   details={"Creating websites is more than just a job for me—it’s a passion. I love the process of transforming ideas into visually appealing and functional digital experiences. As a front-end designer and developer, I enjoy working with tools like HTML, CSS, and JavaScript to bring designs to life. The challenge of ensuring a seamless user experience while maintaining aesthetic appeal excites me. Every project is an opportunity to learn something new and push the boundaries of what’s possible on the web. My goal is to create websites that are not only beautiful but also intuitive and accessible to all users."}
    />

 


<ScrollAnimation animateIn="fadeIn">
  <Title title={"Ray's Clays"}/>
</ScrollAnimation>


<Webdevice 
 description={["I designed and developed a comprehensive e-commerce website for a custom earring company. This project involved creating an engaging and user-friendly interface using HTML, CSS, and JavaScript. I ensured the site was visually appealing and easy to navigate, enhancing the overall user experience."," On the backend, I implemented robust databases using MySQL to manage product inventory, customer information, and order processing efficiently. This combination of technologies allowed me to deliver a seamless and efficient platform for both the business and its customers"]}
 link={"www.raysclays.co.uk"}
 url={["/images/website/rayhome.png",
 "/images/website/rayabout.png", 
     "/images/website/raycustom.png" ,
     "/images/website/raycheck.png" ,
     "/images/website/raycheckout.png"

 ]}
 device = {"url(/images/computer.svg)"}
 width = {"92%"}
 height= {"40vw"}
 transform = {"8"}
 newtransform = {"8"}
 newheight = {"30vw"}
 
 />

<TitleLft titlename={"Paperboat weddings"}
colour = {"White"} />

<ScrollAnimation animateIn="bounceInLeft">
<Webdevice 
 description={["Creating a wedding video site is an exciting and rewarding project. The goal is to design a platform that beautifully showcases the special moments of a couple’s big day. Using HTML, CSS, and JavaScript, I can craft a visually stunning and user-friendly interface that highlights videos in high quality."," The site can include features like video galleries, client testimonials, and easy navigation to ensure a seamless experience for users. Additionally, integrating a secure backend with MySQL allows for efficient management of video uploads, client information, and booking details. This project combines creativity with technical expertise to deliver a memorable and functional website for capturing and sharing wedding memories."]}
 link={"www.paperboatweddings.co.uk"}
 url={["/images/website/weddinghome.png",
  "/images/website/weddingabout.png", 
      "/images/website/weddingkit.png" ,
      "/images/website/weddingpackage.png" ,
      "/images/website/weddingcontact.png"
  ]}
 device = {"url(/images/phone.svg)"}
 width = {"32%"}
 height= {"48vw"}
 transform = {"12"}
 newtransform = {"10"}
 
 />
</ScrollAnimation>


<Title title={"The BookPeople"}
/>

<ScrollAnimation animateIn="bounceInLeft">
<Webdevice 
 description={["Creating front-end elements for theBookPeople involves designing an intuitive and visually appealing interface. Using HTML, CSS, and JavaScript, I develop dynamic components like interactive book carousels, search bars, and navigation menus."," The goal is to enhance the user experience, making browsing and purchasing books enjoyable and engaging."]}
 link={"www.paperboatweddings.co.uk"}
 url={["/images/website/bookpeoplehome.png",
  "/images/website/bookpeopleabout.png", 
      "/images/website/bookpeoplegifts.png" ,
      "/images/website/bookpeoplebest.png" ,
      "/images/website/bookpeopledeliver.png"
  ]}
 device = {"url(/images/tablet.svg)"}
 width = {"52%"}
 height= {"48vw"}
 transform = {"10"}
 newtransform = {"10"}
 newheight = {"20vw"}
 
 />
</ScrollAnimation>



<TitleLft titlename={"CV-Library"} colour = {"White"} />

<ScrollAnimation animateIn="bounceInLeft">
<Webdevice 
 description={["At CV-Library, I work on front-end development for admin pages and product interfaces. Using modern web technologies, I create user-friendly features that enhance the overall experience."," Collaborating with design and backend teams, I ensure seamless integration and functionality, improving the usability of admin tools and our product offerings."]}
 link={"www.paperboatweddings.co.uk"}
 url={["/images/website/cvlwork.png",
  "/images/website/cvlabout.png", 
      "/images/website/cvlcontact.png" ,
      "/images/website/cvlhelp.png" ,
      "/images/website/cvlquiz.png"
  ]}
 device = {"url(/images/laptop.svg)"}
 width = {"78%"}
 height= {"45vw"}
 transform = {"15"}
 newtransform = {"12"}
 newheight = {"43vw"}
 
 />
</ScrollAnimation>

<Title title={"QWERKITY"}/>

<ScrollAnimation animateIn="bounceInLeft">
<Webdevice 
 description={["At QWERKITY, I specialize in website design, crafting visually appealing and highly functional web pages. My work involves using the latest design principles and technologies to create engaging and user-friendly interfaces. ","By collaborating with the development team, I ensure that the designs are not only aesthetically pleasing but also seamlessly integrated with the backend systems. This approach helps in delivering a cohesive and enjoyable user experience, enhancing the overall effectiveness of QWERKITY’s online presence."]}
 link={"www.paperboatweddings.co.uk"}
 url={["/images/website/qwerkity1.png",
  "/images/website/qwerkity2.png", 
      "/images/website/qwerkity1.jpg" ,
      "/images/website/qwerkity4.jpg" ,
      "/images/website/qwerkity5.jpg"
  ]}
 device = {"url()"}
 width = {"78%"}
 height= {"45vw"}
 transform = {"15"}
 newtransform = {"12"}
 newheight = {"30vw"}
 />
</ScrollAnimation>



  <TitleLft titlename={"Projects"}
  colour={"#6BB22B"}
  fontcolour={"white"}  />

<Projects
    projectname = {["CV-Library",
       "The book People",
        "QWERKITY",
      
          "PaperBoat weddings",
              "Rayclays"
                  ]}
    projectdescription = {[
      "At CV-Library, I develop intuitive and user-friendly front-end interfaces for both admin pages and client products, ensuring a seamless and engaging user experience.",
        "At TheBookPeople, I create engaging and user-friendly front-end interfaces for both admin pages and customer-facing products, enhancing the overall user experience.",
          "Working on front-end development at QWERKITY involves crafting intuitive and visually appealing user interfaces that enhance the overall user experience",
           
             
               
                
                "Creating a wedding website involves designing a beautiful and personalized platform to share all the special details and updates about your big day with guests",
                "Developed an e-commerce website for a jewelry brand specializing in earrings"
                
                     ]} 

    projectlogo = {["/images/complogos/cvl.jpg",
                  "/images/complogos/thebookpeople.webp",
                  "/images/complogos/qwerkity.png",
                  
                 
                 
                  "./images/paperboat logo-01.png",
                  "/images/complogos/rayclay.jpg"
                
                 
      
    ]}  />
<Title title={"Skills & software"}/>

<Skills
skills = {["Website design", "Front end Development", "Javascript animation", "backend routing"]}
logo={["/images/figma.png", "/images/pp.jpg", "/images/html.png", "/images/css.png","/images/js.png","/images/react.png" ,"/images/node.png"   ]}
software={["Figma", "Photoshop", "HTML", "CSS",  "Javascript",  "React",  "Node.js"]}/>

<Contact/>




</React.StrictMode>,
)


  )}