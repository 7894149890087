import { useState } from 'react'

import '../css/header.css'

export default function App(props) {
  
  let logo = props.logo;
  
  
  return  (
  <>
    <div className="navbar" id="navbar">
      
      <a href="./videography">Video</a>
      <a href="./animation">Animation</a>
      <a href="./games">Games</a>
      <a href="./"  >
          <img src={logo} className="logo" height="100" title="this is a photo"/>
      </a>
      <a href="./web">Web</a>
      <a href="./graphics">Graphic design</a>
      <a href="./contact">Contact Me</a>
    
    </div>
  </>
  )
}